import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import NewsItem from "./news-item";

import * as styles from "../styles/components/post-featured.module.css";

const FeaturedNews = (props) => {

  const {
    news
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <h2 className={cn("h3", styles.title)}>Editor's Picks</h2>
        <div className="grid">
          {news.map(({_key, news}) => (
            <div key={_key} className={cn("col-12 col-md-6", styles.item)}>
              <NewsItem news={news} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default FeaturedNews;
