import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";
import Icon from "./icon";
import NewsItem from "./news-item";
import NewsCategoryList from "./news-category-list";
import Pagination from "./pagination";

import * as styles from "../styles/components/post-list.module.css";

const NewsList = (props) => {

  const {
    news,
    cats,
    landingTitle,
    currentCat,
    currentPage,
    numPages
  } = props;

  return (
    <div className={styles.root} id="post-list">
      <Container>
        <div className={cn("grid justify-md-between align-center", styles.titleRow)}>
          <div className="col-12 col-md-auto">
            {currentCat ? (
              <>
                <Link className={styles.back} to="/news-updates"><Icon symbol="arrow-left" />{landingTitle}</Link>
                <h1 className={cn("h3", styles.title)}>{currentCat.title}</h1>
              </>
            ) : (
              <h2 className={cn("h3", styles.title)}>All Articles</h2>
            )}
          </div>

          {cats && (<div className="col-12 col-md-auto"><NewsCategoryList items={cats} currentCat={currentCat} /></div>)}
        </div>

        <div className="grid">
          {news.map(({node: news}) => (
            <div key={news.id} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
              <NewsItem news={news} />
            </div>
          ))}
        </div>

        {numPages > 1 && (<Pagination base={currentCat ? "/news-updates/"+currentCat.slug.current : "/news-updates"} currentPage={currentPage} numPages={numPages} />)}
      </Container>
    </div>
  );
}

export default NewsList;
